export default function extractMetaData(data, route) {
  if (!data.allMetaData || !data.allMetaData.metaImage) {
    return {
      title: 'ARTEX - Invest in a fraction of timeless'
    }
  }

  let metas = [];

  if (data.allMetaData.metaTitle) {
    metas.push({
      hid: 'og:title',
      name: 'og:title',
      content: data.allMetaData.metaTitle
    })
  }

  if (data.allMetaData.metaDescription) {
    metas.push({
      hid: 'description',
      name: 'description',
      content: data.allMetaData.metaDescription
    })
    metas.push({
      hid: 'og:description',
      name: 'og:description',
      content: data.allMetaData.metaDescription
    })
  }

  if (data.allMetaData.keywords) {
    metas.push({
      hid: 'keywords',
      name: 'keywords',
      content: data.allMetaData.keywords
    })
  }

  if (data.allMetaData.metaImage.data) {
    metas.push({
      hid: 'og:image',
      property: 'og:image',
      content: data.allMetaData.metaImage.data.attributes.url
    })
  }

  return {
    title: data.allMetaData.metaTitle,
    metas: metas
  }
}
