import { defineStore } from 'pinia'

export const useLayoutStore = defineStore('layout', {
  state: () => ({
    isMobile: false,
    isPortrait: false
  }),
  getters: {
    getIsMobile: (state) => {
      return state.isMobile
    },
    getIsPortrait: (state) => {
      return state.isPortrait
    }
  },
  actions: {
    setIsMobile(value) {
      this.isMobile = value
    },
    setIsPortrait(value) {
      this.isPortrait = value
    }
  }
})
