import showdown from 'showdown'
import extractMetaDataUtils from '~/utils/extractMetaData'
import { mapStores } from 'pinia'
import { useLayoutStore } from '~/stores/layout'

export default {
  computed: {
    ...mapStores(useLayoutStore),
    isMobile() {
      return this.layoutStore.getIsMobile
    }
  },
  methods: {
    formatUriDisplay(url) {
      return url.slice(0, url.lastIndexOf('/')).replace(/^.{3,5}:\/\//, '')
    },
    assurePrefix(url) {
      return url.match(/^.{3,5}:\/\//) ? url : `https://${url}`
    },
    formatedText(text, objectReturn) {
      const lines = text.split('\n')
      let title = ''
      const object = []

      for (let i = 0; i < lines.length; i++) {
        if (lines[i].search(/[blue]/) > -1) {
          const text = lines[i].replaceAll('[blue]', '<span class="text-blue">').replaceAll('[/blue]', '</span>')
          title += text
          object.push(text)
        } else {
          title += lines[i]
          object.push(lines[i])
        }
      }

      return objectReturn ? object : title
    },
    getImagePath(image) {
      const path = window.location.href.indexOf('localhost') > -1 ? this.$config.public.AdminUrl : ''
      return image && image.url ? `${path}${image.url}` : ''
    },
    extractMetaData(data, path) {
      return extractMetaDataUtils(data, path)
    },
    formatedMarkdownText(text) {
      const converter = new showdown.Converter()
      return converter.makeHtml(text)
    }
  }
}
